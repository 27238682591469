@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Noto Sans TC;
    unicode-range: U+4E00-9FFF;
  }
  @font-face {
    font-family: Gopher;
    unicode-range: U+0020-0071;
    letter-spacing: 0.1em;
  }
}

@layer utilities {
  .writing-mode-horizontal { writing-mode: horizontal-tb }
  .writing-mode-vertical { writing-mode: vertical-lr }
  .writing-mode-vertical-rl { writing-mode: vertical-rl }

  .text-orientation-mixed { text-orientation: mixed }
  .text-orientation-upright { text-orientation: upright }
  .text-orientation-sideways { text-orientation: sideways }

  .break-inside-avoid {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

@layer utilities {
  @variants responsive {
    .column-1 { column-count: 1 }
    .column-2 { column-count: 2 }

    .cg-8 { column-gap: 2rem }
    .cg-9 { column-gap: 2.25rem }
    .cg-10 { column-gap: 2.5rem }
  }
}
