#article > section > p {
  line-height: 2rem;
  margin-top: 1.25rem;
}

.article-card .header {
  position: relative;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0) 50%
  );
  z-index: 1;
}

.article-card .header::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 50%,
    #cce3e2 50%
  );
  z-index: -1;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  opacity: 0;
}

.article-card:hover .header::before {
  opacity: 1;
}

.article-card:hover .article-card-img {
  opacity: 0.75;
}

.interviewee-links a {
  text-decoration: underline;
}

.featured-img img {
  object-fit: contain;
}

.article-card-transition-exit-active {
  opacity: 0;
  animation: fadeout .3s ease-in;
}

@keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; } 
}

.menu li > a {
  padding: 0;
}

.nav-item-covered {
  clip-path: inset(0);
}

.nav-item-uncovered {
  clip-path: inset(0 0 0 100%);
}

.transition-clip-path {
  transition: 0.3s ease clip-path;
}

.highlight {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 75%, #cce3e2 25%)
}
