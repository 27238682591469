img {
  margin: 0;
}

p {
  text-align: justify;
  margin: 0;
}

figcaption {
  font-size: 0.8375rem;
  text-align: center;
}

a:link {
  color: inherit;
}

a:visited {
  color: inherit;
}

article {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
